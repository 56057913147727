// COMPOSABLE
// IMPORT LIBRERIE VARIE
import { ref, computed } from "vue";
import { useStore } from "vuex";
import { addRichiesta } from "@/requests/raasAddRichiesta";
import { alertFailed, alertSuccess } from "@/composables/swAlert";
import Swal from "sweetalert2/dist/sweetalert2.min.js";

export function useAddRaas(listName, id_tipologia) {
  // DICHIARAZIONE VARIABILI
  const refreshContent = ref(0);
  const isSendingRaas = ref(false);
  const store = useStore();
  const results = computed(() =>
    store.getters.getStateFromName(`results${listName}`)
  );
  const anno = computed(() => store.getters.stagioneSelected);

  const sendRaasSelezionati = async () => {
    const societaDaInviare = ref([]);
    results.value.forEach((element) => {
      if (element.selected) {
        societaDaInviare.value.push(element);
      }
    });
    if (!societaDaInviare.value.length)
      alertFailed("Selezionare almeno un elemento (max. 10)");
    else if (societaDaInviare.value.length > 10) {
      alertFailed("Selezionare al massimo 10 elementi e riprovare");
      return;
    } else {
      Swal.fire({
        title: "Sei Sicuro?",
        text: "Procedendo gli elementi selezionati verranno inviati",
        icon: "warning",
        buttonsStyling: false,
        showCancelButton: true,
        cancelButtonText: "Annulla",
        confirmButtonText: "Invia",
        allowOutsideClick: false,
        customClass: {
          confirmButton: "btn fw-bold btn-light-success",
          cancelButton: "btn fw-bold btn-light-warning",
        },
      }).then(async (e) => {
        if (e.isDismissed) {
          return;
        }
        if (e.isConfirmed) {
          sendRaas(societaDaInviare.value);
        }
      });
    }
  };

  const sendRaasTutti = async () => {
    if (!results.value.length) alertFailed("Impossibile inviare la richiesta");
    else if (results.value.length > 10) {
      alertFailed("Selezionare al massimo 10 elementi e riprovare");
      return;
    } else {
      Swal.fire({
        title: "Sei Sicuro?",
        text: "Procedendo gli elementi visualizzati nella lista verranno inviati",
        icon: "warning",
        buttonsStyling: false,
        showCancelButton: true,
        cancelButtonText: "Annulla",
        confirmButtonText: "Invia",
        allowOutsideClick: false,
        customClass: {
          confirmButton: "btn fw-bold btn-light-success",
          cancelButton: "btn fw-bold btn-light-warning",
        },
      }).then(async (e) => {
        const arraySocietaDaInviare = ref([]);
        if (e.isDismissed) {
          return;
        }
        if (e.isConfirmed) {
          results.value.forEach((element) => {
            if (element.id_stato != 2)
              arraySocietaDaInviare.value.push(element);
          });
          await sendRaas(arraySocietaDaInviare.value);
        }
      });
    }
  };

  const sendRaas = async (societaArray) => {
    isSendingRaas.value = true;
    await addRichiesta(id_tipologia, anno.value, societaArray).then((res) => {
      if (res.data.result === "OK") {
        alertSuccess("Richiesta inviata con successo");
        refreshContent.value = refreshContent.value + 1;
      } else {
        alertFailed(
          "Non è possibile inviare la richiesta. Riprovare più tardi"
        );
      }
      isSendingRaas.value = false;
    });
  };

  return {
    sendRaasSelezionati,
    sendRaasTutti,
    refreshContent,
    isSendingRaas,
  };
}
