<template>
  <div
    class="modal fade"
    tabindex="-10"
    id="modal_errori_raas"
    data-bs-keyboard="false"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Errori</h5>
        </div>
        <template v-if="!loaded">
          <div class="modal-body">
            <div class="mx-auto my-auto text-center">
              <div class="spinner-border text-gray-600" role="status"></div>
              <span class="text-gray-600 ps-3 fs-2">Loading</span>
            </div>
          </div>
          <div class="modal-footer"></div>
        </template>
        <div
          v-else-if="results && results.length === 0 && loaded"
          class="mx-auto my-auto text-center pt-5 mt-5 mb-5 pb-5"
        >
          <!-- <i class="bi bi-exclamation-triangle fs-2 text-black pb-4"></i> -->
          <span class="text-gray-700 ps-3 fs-2">
            Attenzione! Nessun risultato.
          </span>
        </div>
        <div
          v-else-if="status != 200 && status != 204"
          class="mx-auto my-auto text-center pt-5 mt-5 mb-5 pb-5"
        >
          <!-- <i class="bi bi-exclamation-triangle fs-2 text-black pb-4"></i> -->
          <span class="text-gray-700 ps-3 fs-2">
            Attenzione! Si è verificato un errore. Riprovare più tardi.
          </span>
        </div>
        <template v-else>
          <div class="modal-body">
            <div class="card-body p-6">
              <ul>
                <li v-for="(err, index) in results" :key="index">
                  {{ err.errore }}
                </li>
              </ul>
            </div>
          </div>

          <div class="modal-footer">
            <span
              type="button"
              class="me-3 badge bg-light-danger rounded text-danger fs-6"
              data-bs-dismiss="modal"
              aria-label="Close"
              id="modalEsporta"
            >
              Chiudi
            </span>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import { useStore } from "vuex";
import { computed, watch, getCurrentInstance } from "vue";
export default {
  name: "ErroriRaas",
  props: {
    id_societa: {
      type: [Number, String],
      required: true,
    },
    link: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const store = useStore();
    const anno = computed(() => store.getters.stagioneSelected);
    const instance = getCurrentInstance();
    const globalApi =
      instance.appContext.config.globalProperties.$apiListGlobal;
    const getErrorsFromIdSoc = () => {
      const localKeys = {
        anno: anno.value,
        id_societa: props.id_societa,
      };
      store.dispatch("setStoreListData", {
        keys: localKeys,
        apiLink: globalApi[props.link],
        itemName: "raas_affiliazioni_errori_list",
      });
    };

    watch(
      () => props.id_societa,
      () => {
        getErrorsFromIdSoc();
      }
    );
    return {
      results: computed(() =>
        store.getters.getStateFromName("resultsraas_affiliazioni_errori_list")
      ),
      loaded: computed(() =>
        store.getters.getStateFromName("loadedraas_affiliazioni_errori_list")
      ),
      record: computed(() =>
        store.getters.getStateFromName("recordraas_affiliazioni_errori_list")
      ),
      status: computed(() =>
        store.getters.getStateFromName("statusraas_affiliazioni_errori_list")
      ),
    };
  },
};
</script>

<style></style>
